<template>
  <div class="container">
    <!-- 页面选择 -->
    <div class="list-page">
      <el-tabs v-model="activePage" @tab-click="pageRedirect()">
        <el-tab-pane label="文章列表" name="/article/list"></el-tab-pane>
        <el-tab-pane label="文章数据" name="/article/data"></el-tab-pane>
        <el-tab-pane label="员工数据" name="/article/staff"></el-tab-pane>
      </el-tabs>
    </div>

    <!-- 分页面 -->
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      activePage: "/article/list", // 当前选择的页面
    }
  },
  mounted(){
    this.activePage = this.$route.path;
  },
  watch: {
    // 初始化当前选择的分类标签
    '$route': function(){
      this.activePage = this.$route.path;
    }
  },
  methods: {
    // 跳转到对应的页面
    pageRedirect(){
      if(this.activePage == this.$route.path) return;
      this.$router.replace({path: this.activePage});
    }
  }
}
</script>

<style lang="scss" scoped>
.list-page{
  ::v-deep .el-tabs__header{
    line-height: 60px;
  }
  ::v-deep .el-tabs__nav-wrap::after{
    height: 1px;
    background-color: #e8e8e8;
  }
  ::v-deep .el-tabs__item{
    width: 100px;
    text-align: center;
  }
}
.content{
  background-color: #fff;
  padding: 15px;
}
</style>